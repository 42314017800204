$header-height: 76px;

@mixin desktop {
  @media (min-width: 768px) {
  // , screen and (max-width: 900px) and (max-height: 430px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
};

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
};

@mixin sf_desktop {
  @media (min-width: 936px) {
  // , screen and (max-width: 900px) and (max-height: 430px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
};

@mixin sf_mobile {
  @media (max-width: 935px) {
    @content;
  }
};

@mixin safe-area-inset-bottom {
  // because iOS ignores the next rule in PWA installed mode
  @media all and (display-mode: standalone) {
    @supports (-webkit-touch-callout: none) {
      padding-bottom: 34px;
    }
  }

  // https://webkit.org/blog/7929/designing-websites-for-iphone-x/
  padding-bottom: max(12px, env(safe-area-inset-bottom));
}
