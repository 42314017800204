$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
$material-design-icons-class-icons-generate-codepoints: false;
@import '~material-design-icons-iconfont/src/material-design-icons';

@import '~@angular/cdk/overlay-prebuilt.css';

@import "typography";
@import "layout";

@import "app/self-referral/self-referral.global.scss";

html, body {
  height: 100%;
  overflow-y: auto;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

input {
  margin: 0;
}

body, button {
  margin: 0;
  font-family: $font-family;
}

img {
  vertical-align: bottom;
}

// Global typography classes
@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    .text#{$size-name}#{$weight-name} {
      @extend %text#{$size-name}#{$weight-name};
    }

    @each $color-name, $color in $typography-colors {
      .text#{$size-name}#{$weight-name}-#{$color-name} {
        @extend %text#{$size-name}#{$weight-name}-#{$color-name};
      }
    }
  }
}

// Results in:
//   .text-small
//   .text-small-medium
//   .text-small-medium-primary
//   ...

h3 {
  font-weight: 500;
  font-size: 18px;

  @include desktop {
    font-size: 24px;
  }
}

// Flex helpers
.flex-fill {
  flex: 1 1 auto;
}
