@import "color";

$font-family: 'Montserrat', sans-serif;

// For future: do not use h*, use sizes like 20, 24
$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-40: 40px;
$font-size-52: 52px;
$font-size-60: 60px;

$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-big: 16px;
$font-size-h4: 18px;
$font-size-h3: 24px;
$font-size-h1: 36px;

$font-sizes: (
  "-8": $font-size-8,
  "-10": $font-size-10,
  "-12": $font-size-12,
  "-13": $font-size-13,
  "-14": $font-size-14,
  "-16": $font-size-16,
  "-18": $font-size-18,
  "-20": $font-size-20,
  "-24": $font-size-24,
  "-32": $font-size-32,
  "-36": $font-size-36,
  "-40": $font-size-40,
  "-52": $font-size-52,
  "-60": $font-size-60,

  "-small": $font-size-small,
   "": $font-size-normal,
   "-big": $font-size-big,
   "-h4": $font-size-h4,
   "-h3": $font-size-h3,
   "-h1": $font-size-h1
);

$font-weight-normal: normal;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weights: (
  "": $font-weight-normal,
  "-medium": $font-weight-medium,
  "-bold": $font-weight-bold
);

@each $size-name, $size in $font-sizes {
  @each $weight-name, $weight in $font-weights {
    // @debug text#{$size-name}#{$weight-name};
    %text#{$size-name}#{$weight-name} {
      font-size: $size;
      font-weight: $weight;
    }

    // colors
    @each $color-name, $color in $typography-colors {
      // @debug text#{$size-name}#{$weight-name}-#{$color-name};
      %text#{$size-name}#{$weight-name}-#{$color-name} {
        font-size: $size;
        font-weight: $weight;
        color: $color;
      }
    }
  }
}

// Results in extends
//   %text-small
//   %text-small-medium
//   %text-small-medium-primary
