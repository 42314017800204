$color-grey24: #363A3D;
$color-grey60: #939699;
$color-grey72: #B0B4B8;
$color-grey80: #C8CACC;
$color-grey88: #DCDEE0;
$color-gray92: #E7E8EB;
$color-grey96: #F2F4F5;

$color-primary: #ED7B25;
$color-primary-hover: #D37129;
$color-secondary: #16324A;
$color-tertiary: #A5C9DD;

$color-white: #fff;
$color-green: #2E994A;
$color-red: #ED3A26;
$color-yellow: #EDCC26;

$typography-colors: (
  "primary": $color-primary,
  "secondary": $color-secondary,
  "tertiary": $color-tertiary,
  "white": $color-white,
  "green": $color-green,
  "red": $color-red,
  "grey24": $color-grey24,
  "grey60": $color-grey60,
  "grey72": $color-grey72
);
