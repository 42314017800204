@import "includes";

.sr-page {
  display: block;

  padding: 24px 16px;

  @include sf_desktop {
    max-width: 688px;
    padding: 32px 56px;
  }

  hfc-step-progress-bar {
    margin-bottom: 18px;

    @include sf_desktop {
      margin-bottom: 40px;
    }
  }
}

.sr-page-heading {
  @extend %text-20-medium-secondary;

  margin-bottom: 24px;

  @include sf_desktop {
    margin-bottom: 32px;

    font-size: $font-size-h3;
  }

  .sr-page-subheading {
    @extend %text-small-secondary;

    @include sf_desktop {
      font-size: 14px;
    }

    // line-height: 28px;
    &:not(:empty) {
      margin: 6px 0;
    }
  }
}

.sr-separator {
  width: 100%;
  border: 1px solid #ebedf0;
}

.sr-page-buttons {
  display: flex;
  justify-content: space-between;

  background: #fff;

  @include sf_desktop {
    margin-top: 40px;
  }

  @include sf_mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    padding: 12px;
    padding-bottom: max(12px, env(safe-area-inset-bottom));
  }

  hfc-button:not(:last-child) {
    @include sf_mobile {
      margin-right: 12px;
    }
  }

  .flex-1 {
    @include sf_mobile {
      flex: 1;
    }
  }

  .flex-o35 {
    @include sf_mobile {
      flex: 35;
    }
  }

  .flex-o65 {
    @include sf_mobile {
      flex: 65;
    }
  }
}

.sr-form {
  @include sf_desktop {
    max-width: 356px;
  }
}

.sr-form-field {
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;

  &.margin-bottom-l {
    margin-bottom: 40px;
  }

  &>label {
    margin-bottom: 8px;

    @extend %text-grey24;
    line-height: 16px;
  }
}

.sr-form-options-field {
  @extend .sr-form-field;

  &>label {
    margin-bottom: 22px;

    @include sf_desktop {
      margin-bottom: 24px;
    }
  }
}

.sr-form-big-field {
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;


  @include sf_desktop {
    margin-bottom: 32px;
  }

  &>label {
    margin-bottom: 16px;

    @extend %text-20-medium-secondary;

    @include sf_desktop {
      font-size: $font-size-h3;
    }

    .hint {
      @extend %text-14-medium-grey60;
    }
  }
}

.sr-form-hint {
  @extend %text-12-secondary;

  @include sf_mobile {
    margin-top: 8px;
  }

  @include sf_desktop {
    font-size: $font-size-14;
    line-height: 28px;
  }

  a, a:active, a:visited {
    color: $color-secondary;
  }
}

.sr-form-error {
  display: flex;
  align-items: center;

  @extend %text-red;

  &.margin-bottom {
    margin-bottom: 12px;
  }

  &.icon {
    &::before {
      content: "error";
      font-family: 'Material Icons', 'serif';
      margin-right: 8px;
      font-size: 16px;
    }
  }
}

.sr-summary {
  .sr-record {
    display: flex;

    margin-bottom: 12px;
    line-height: 16px;

    .label {
      flex: 0 0 120px;
      width: 120px;

      @extend %text-small-medium-grey60;
    }

    .value {
      margin-left: 12px;
      @extend %text-medium-secondary;
    }
  }
}
